.square {
    width: 50rem;
    height: 30rem;

    
  }
  .logintxt
  {
    width: 70%;
    height: 3.5rem;
  }

  .loginbtn
  {

    height: 3.5rem;
}
.login2 
{

background-color:deepskyblue ;
}
.login1
{
    height: 20rem;
    border-color: deepskyblue   ;
    border-radius: 5px;
}

.typed-cursor 
{
    font-size: 36px;
    color: white;
}
#typed
{
    color: white;

}
.contenedor
{
    height: 80vh;
}

